<template>
	<div>
		<ContentHeader title="Laporan Farmasi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title"><div style="#007bff">Daftar Laporan</div></h3>
          </div>
          <div class="card-body">
          	<div class="row">
        		  <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
        						<router-link to="/laporan-stok-produk" class="cursor-pointer">
		                	<h3 class="card-title">Laporan Stok Produk</h3>
        						</router-link>		
		              </div>
		            </div>
		          </div>		
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/penjualan-produk-per-transaksi" class="cursor-pointer">
		                	<h3 class="card-title">Penjualan Produk Per Transaksi</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/produk-terjual-per-periode" class="cursor-pointer">
		                	<h3 class="card-title">Data Obat Terjual Per Periode</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-obat-expired" class="cursor-pointer">
		                	<h3 class="card-title">Obat Expired</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		        </div>
		        <div class="row">
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-stok-opname" class="cursor-pointer">
		                	<h3 class="card-title">Stok Opname</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-penerimaan-stok" class="cursor-pointer">
		                	<h3 class="card-title">Penerimaan Stok (Cabang)</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3" v-if="cabang.jenis == 'pusat'">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-mutasi-obat" class="cursor-pointer">
		                	<h3 class="card-title">Mutasi Obat (Gudang)</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3" v-if="cabang.jenis == 'pusat'">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-pembelian-obat" class="cursor-pointer">
		                	<h3 class="card-title">Pembelian Obat (Gudang)</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		        </div>
          </div>
      	</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'

import store from '@/store'
import { computed } from 'vue'

export default {
	components: {
		ContentHeader
	},
	setup(){
    const cabang = computed(() => store.getters['auth/cabang'])
    console.log(cabang.value)

    return { cabang }
	}
}
</script>